import { Disclosure } from '@headlessui/react';
import { FaXTwitter } from 'react-icons/fa6';
import { IoClose, IoMenu } from 'react-icons/io5';
import LogoBanana from '../assets/Logo_Banana.png';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <header className="sticky top-0 z-50">
      <Disclosure as="nav" className="bg-white/90">
        {({ open }) => (
          <>
            <div className="p-6 lg:px-20">
              <div className="flex h-auto justify-between">
                <div className="w-full flex justify-between">
                  <a href="#home" className="flex flex-shrink-0 items-center">
                    <img
                      className="h-14 md:h-20 w-auto"
                      src={LogoBanana}
                      alt="xTRA BORED Logo"
                    />
                  </a>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    <a
                      href="#intro"
                      className="max-w-[115px] leading-none inline-flex items-center px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      LONG STORY SHORT...
                    </a>
                    <a
                      href="#art"
                      className="max-w-[130px] leading-none inline-flex items-center px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      SAME SAME, BUT DIFFERENT...
                    </a>
                    {/* <a
                      href="#contribute"
                      className="max-w-[130px] leading-none inline-flex items-center px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      DREAMS OF AN ORIGINAL APE...
                    </a> */}
                    <a
                      href="#mutants"
                      className="max-w-[115px] leading-none inline-flex items-center px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      MUTANTS YOU SAY?
                    </a>
                    <div className="flex items-center">
                      <Link to="/gallery">
                        <button
                          type="button"
                          className="max-w-[150px] border-2 border-gray-500 h-10 rounded-md bg-white px-6 py-2 text-gray-500 text-sm font-semibold shadow-sm hover:bg-accent hover:text-black hover:border-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                        >
                          GALLERY
                        </button>
                      </Link>
                    </div>
                    <a
                      href="https://twitter.com/ZEP_BEEPEE"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="max-w-[115px] leading-none inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      <FaXTwitter
                        className="block h-8 w-8"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </div>

                <div className="-mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <IoClose className="block h-10 w-10" aria-hidden="true" />
                    ) : (
                      <IoMenu className="block h-10 w-10" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2 flex flex-col justify-center items-center">
                <Disclosure.Button
                  as="a"
                  href="#intro"
                  className="leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500  hover:text-gray-700"
                >
                  LONG STORY SHORT...
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#art"
                  className="leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500  hover:text-gray-700"
                >
                  SAME SAME, BUT DIFFERENT...
                </Disclosure.Button>
                {/* <Disclosure.Button
                  as="a"
                  href="#contribute"
                  className="leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500  hover:text-gray-700"
                >
                  DREAMS OF AN ORIGINAL APE...
                </Disclosure.Button> */}
                <Disclosure.Button
                  as="a"
                  href="#mutants"
                  className="leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500  hover:text-gray-700"
                >
                  MUTANTS YOU SAY?
                </Disclosure.Button>

                <div className="flex items-center leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500 hover:text-gray-700">
                  <Link to="/gallery">
                    <button
                      type="button"
                      className="border-2 border-gray-500 h-auto rounded-md bg-white px-6 py-2 text-gray-500 text-sm font-semibold shadow-sm hover:bg-accent hover:text-black hover:border-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                      xTRA BORED GALLERY
                    </button>
                  </Link>
                </div>

                <Disclosure.Button
                  as="a"
                  href="https://twitter.com/ZEP_BEEPEE"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="leading-none block  border-transparent py-4 pl-3 pr-4 text-base font-bold text-gray-500  hover:text-gray-700"
                >
                  <FaXTwitter className="block h-5 w-5" aria-hidden="true" />
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default NavBar;
