import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';

const InfoModal = ({ isInfoModalOpen, setIsInfoModalOpen }: any) => {
  return (
    <Transition.Root show={isInfoModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsInfoModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="modal-background-image min-h-[500px] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                    onClick={() => setIsInfoModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <IoMdClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      xTRA INFO
                    </Dialog.Title>
                    <div className="mt-2 text-sm md:text-base">
                      <p className="text-gray-700">
                        The image you're checking out right now is actually a
                        lower-quality version of the original. If you'd like the
                        high-definition version, just hit me up on Twitter via
                        DM. <br />
                        And guess what? You can also request a background-free
                        version, which is just perfect for creating some
                        seriously bad*ss stickers! 😎 <br />
                        - <br />
                        Help a fellow dgen - share your Ape and give a shoutout
                        to{' '}
                        <a
                          href="https://twitter.com/ZEP_BEEPEE"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 underline"
                        >
                          @zep_beebee
                        </a>{' '}
                        <br />
                        Thank's fam :)
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InfoModal;
