import '../App.css';
import NavBar from '../components/NavBar';
import Home from '../views/Home';
import Intro from '../views/Intro';
import Traits from '../views/Traits';
import Contribute from '../views/Contribute';
import Mutants from '../views/Mutants';
import Footer from '../components/Footer';

const Main = () => {
  return (
    <div>
      <NavBar />
      <Home />
      <Intro />
      <Traits />
      {/* <Contribute /> */}
      <Mutants />
      <Footer />
    </div>
  );
};

export default Main;
