import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../App.css';
import SearchBar from '../components/SearchBar';
import ApeDetails from '../components/ApeDetails';
import ShowcaseNavigator from '../components/ShowcaseNavigator';
import Spinner from '../components/Spinner';
import ImageModal from '../components/ImageModal';
import InfoModal from '../components/InfoModal';
import xtraBoredPFP from '../assets/xtraBoredPFP.png';
import useImageLoader from '../utils/useImageLoader';
import useModalState from '../utils/useModalState';
import { baycData } from '../data/baycData';
import ImageActions from './ImageActions';

const Showcase = () => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [apeDetails, setApeDetails] = useState<any>();
  const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false);
  const { imageUrl, isLoading, loadImage } = useImageLoader(xtraBoredPFP);
  const {
    isXtraModalOpen,
    isOgModalOpen,
    isInfoModalOpen,
    openXtraModal,
    closeXtraModal,
    openOgModal,
    closeOgModal,
    openInfoModal,
    closeInfoModal,
  } = useModalState();
  const defaultImage = xtraBoredPFP;
  const tweetText = 'Feeling xTRA BORED!';
  const tweetText2 = 'Find the others here: ';
  const webUrl = 'https://www.xtrabored.xyz';

  const transformData = (originalData: any) => {
    const ipfsHash = originalData.metadata.image.split('ipfs://')[1];
    const attributesObject = originalData.metadata.attributes.reduce(
      (acc: any, attr: any) => {
        const key = attr.trait_type.toLowerCase();
        acc[key] = attr.value;
        return acc;
      },
      {}
    );

    return {
      ipfsHash: ipfsHash,
      attributes: attributesObject,
      id: originalData.id,
    };
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0 && value <= 9999) {
      setIsInvalidValue(false);
      setInputValue(value);
    } else {
      setIsInvalidValue(true);
    }
  };

  const handleSearch = () => {
    setIsSearch(true);
    const numericValue = parseInt(inputValue, 10);
    const stringValue = numericValue.toString();
    const url = `https://xtra-bored-bucket.s3.amazonaws.com/${stringValue}.png`;
    const targetId = stringValue;
    const foundApe = baycData.find(
      (ape: { id: string }) => ape.id === targetId
    );
    if (foundApe) {
      const apeData = transformData(foundApe);
      setApeDetails(apeData);
      setIsFlipped(false);
      setIsNavigating(false);
      loadImage(url);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      handleSearch();
    }
  };

  const handleClear = () => {
    setIsSearch(false);
    setInputValue('');
    setIsInvalidValue(false);
    setIsFlipped(false);
    loadImage(defaultImage);
  };

  const flipImages = () => {
    setIsFlipped(!isFlipped);
  };

  const getPrevApe = () => {
    setIsNavigating(true);
    setInputValue(String(Number(inputValue) - 1));
  };
  const getNextApe = () => {
    setIsNavigating(true);
    setInputValue(String(Number(inputValue) + 1));
  };
  const getRandomApe = () => {
    setIsNavigating(true);
    setInputValue(String(Math.floor(Math.random() * 10000)));
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (Number(inputValue) < 9999) {
        getNextApe();
      }
    },
    onSwipedRight: () => {
      if (Number(inputValue) > 0) {
        getPrevApe();
      }
    },
    trackMouse: true,
  });

  const shareImage = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText + '\n' + imageUrl
    )}&url=${encodeURIComponent(tweetText2 + webUrl)}`;

    window.open(twitterUrl, '_blank', 'width=550,height=550');
  };

  useEffect(() => {
    handleClear();
  }, []);

  useEffect(() => {
    if (isNavigating) {
      handleSearch();
    }
  }, [isNavigating]);

  return (
    <>
      <SearchBar
        inputValue={inputValue}
        isInvalidValue={isInvalidValue}
        onInputChange={handleInputChange}
        onSearchClick={handleSearch}
        onClearClick={handleClear}
        onKeyDown={handleKeyDown}
      />
      <div className="flex justify-center items-center mt-6 px-4">
        <InfoModal
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={closeInfoModal}
        />
        {isOgModalOpen && (
          <ImageModal
            imageUrl={`https://ipfs.io/ipfs/${apeDetails.ipfsHash}`}
            onClose={closeOgModal}
          />
        )}
        {isXtraModalOpen && (
          <ImageModal imageUrl={imageUrl} onClose={closeXtraModal} />
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {isSearch ? (
              <div
                {...handlers}
                className="flex flex-wrap gap-6 justify-center items-center mx-4"
              >
                <span className="w-full text-center text-2xl font-permanent-marker block sm:hidden leading-none">
                  SW(APE) AROUND!
                </span>
                <div>
                  <div className="relative w-full max-w-xl mx-auto">
                    <img
                      className={`absolute inset-0 w-full object-contain rounded-lg transition-transform duration-500 ease-in-out transform ${
                        isFlipped ? 'z-10 rotate-0' : '-z-0 -rotate-3'
                      }
              `}
                      src={`https://ipfs.io/ipfs/${apeDetails.ipfsHash}`}
                      onClick={openOgModal}
                      alt="Original Ape"
                    />
                    <img
                      className={`w-full object-contain rounded-lg shadow-md bg-gray-200 transition-transform duration-500 ease-in-out transform ${
                        isFlipped ? '-z-10 -rotate-3' : 'z-10 rotate-0'
                      }`}
                      src={imageUrl}
                      onClick={openXtraModal}
                      alt="xTRA BORED Ape"
                    />

                    <ImageActions
                      expandImage={isFlipped ? openOgModal : openXtraModal}
                      shareImage={shareImage}
                      flipImages={flipImages}
                      openInfoModal={openInfoModal}
                      isFlipped={isFlipped}
                      imageUrl={imageUrl}
                    />
                  </div>
                </div>

                {apeDetails && (
                  <div className="flex flex-col justify-center items-center">
                    <ApeDetails apeDetails={apeDetails} isFlipped={isFlipped} />
                    <ShowcaseNavigator
                      inputValue={inputValue}
                      getPrevApe={getPrevApe}
                      getNextApe={getNextApe}
                      getRandomApe={getRandomApe}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full h-screen max-w-xl mx-4">
                <img
                  className={`w-full object-contain rounded-lg shadow-md bg-gray-200`}
                  src={defaultImage}
                  alt="xTRA BORED Default"
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Showcase;
