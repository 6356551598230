import { FaXTwitter, FaExpand, FaPlus } from 'react-icons/fa6';
import { MdFlipToFront, MdFlipToBack } from 'react-icons/md';

interface ImageActionsProps {
  shareImage: () => void;
  expandImage: () => void;
  flipImages: () => void;
  openInfoModal: () => void;
  isFlipped: boolean;
  imageUrl: string;
}

const ImageActions: React.FC<ImageActionsProps> = ({
  shareImage,
  expandImage,
  flipImages,
  openInfoModal,
  isFlipped,
  imageUrl,
}) => {
  return (
    <>
      <button
        onClick={flipImages}
        className={`z-10 absolute flex top-0 m-4 p-2 rounded items-center font-semibold bg-white/50 text-[#2b2b2b] hover:text-white hover:bg-[#2b2b2b]`}
      >
        {isFlipped ? (
          <MdFlipToBack className="h-6 w-6" />
        ) : (
          <MdFlipToFront className="h-6 w-6" />
        )}
      </button>
      <button
        onClick={expandImage}
        className={`z-10 absolute flex top-0 right-0 m-4 p-2 rounded items-center font-semibold bg-white/50 text-[#2b2b2b] hover:text-white hover:bg-[#2b2b2b]`}
      >
        <FaExpand className="h-6 w-6" />
      </button>
      {!isFlipped && (
        <>
          <button
            onClick={openInfoModal}
            title="Share this xTRA BORED ape on Twitter"
            className={`z-10 absolute flex top-12 right-0 m-4 p-2 rounded items-center font-semibold bg-white/50 text-[#2b2b2b] hover:text-white hover:bg-[#2b2b2b]`}
          >
            <FaPlus className="h-6 w-6" />
          </button>
          {/* <button
            onClick={shareImage}
            title="Share this xTRA BORED ape on Twitter"
            className={`z-10 absolute flex top-24 right-0 m-4 p-2 rounded items-center font-semibold bg-white/50 text-[#2b2b2b] hover:text-white hover:bg-[#2b2b2b]`}
          >
            <FaXTwitter className="h-6 w-6" />
          </button> */}
        </>
      )}
    </>
  );
};

export default ImageActions;
