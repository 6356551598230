import React from 'react';
import ApeAttribute from './ApeAttribute';
import {
  FaHatCowboySide,
  FaTshirt,
  FaGlasses,
  FaRegSquare,
  FaSlackHash,
} from 'react-icons/fa';
import { IoEar, IoBody } from 'react-icons/io5';
import { RiUserSmileFill } from 'react-icons/ri';

interface ApeDetailsProps {
  apeDetails?: {
    id: string;
    attributes: {
      background?: string;
      fur?: string;
      clothes?: string;
      mouth?: string;
      eyes?: string;
      hat?: string;
      earring?: string;
    };
  };
  isFlipped: boolean;
}

const ApeDetails: React.FC<ApeDetailsProps> = ({ apeDetails, isFlipped }) => {
  if (!apeDetails) {
    return null;
  }

  return (
    <div className="text-[#2b2b2b] text-xs sm:text-base font-permanent-marker font-semibold h-4/5 my-6 rounded-md px-6 py-6 w-auto max-w-[420px] sharp-fold shadow-left-bottom rotate-6 z-20">
      <div className="w-full flex justify-center mt-2 text-xl underline">
        <span>Ape details</span>
      </div>
      <ApeAttribute
        icon={<FaSlackHash className="inline mr-2 mr-4" />}
        label="id"
        value={apeDetails.id}
        isFlipped={true}
      />
      <ApeAttribute
        icon={<FaRegSquare className="inline mr-2 mr-4" />}
        label="Background"
        value={apeDetails.attributes.background}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<IoBody className="inline mr-2 mr-4" />}
        label="Fur"
        value={apeDetails.attributes.fur}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<FaTshirt className="inline mr-2 mr-4" />}
        label="Clothes"
        value={apeDetails.attributes.clothes}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<RiUserSmileFill className="inline mr-2 mr-4" />}
        label="Mouth"
        value={apeDetails.attributes.mouth}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<FaGlasses className="inline mr-2 mr-4" />}
        label="Eyes"
        value={apeDetails.attributes.eyes}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<FaHatCowboySide className="inline mr-2 mr-4" />}
        label="Hat"
        value={apeDetails.attributes.hat}
        isFlipped={isFlipped}
      />
      <ApeAttribute
        icon={<IoEar className="inline mr-2 mr-4" />}
        label="Earring"
        value={apeDetails.attributes.earring}
        isFlipped={isFlipped}
      />
      {/* <p className="smMsg text-sm text-center text-accent-dark italic px-6 pt-4">
        Help a fellow dgen - share your Ape and give a shoutout to @ZEP_BEEPEE{' '}
        <br />
        Thank's fam :)
      </p> */}
    </div>
  );
};
export default ApeDetails;
