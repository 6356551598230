import IntroPc from '../assets/Intro_Pc.png';
import IntroMobile from '../assets/Intro_Mobile.png';

export default function Home() {
  return (
    <div id="home">
      <div className="hidden w-full px-6 sm:flex sm:px-8 lg:px-20">
        <img src={IntroPc} alt="xTRA BORED APES" />
      </div>
      <div className="w-full sm:hidden">
        <img src={IntroMobile} alt="xTRA BORED APES" />
      </div>
    </div>
  );
}
