import '../App.css';
import NavBarGallery from '../components/NavBarGallery';
import Showcase from '../components/Showcase';

const Gallery = () => {
  return (
    <div className="background-image">
      <NavBarGallery />
      <Showcase />
    </div>
  );
};
export default Gallery;
