import { ReactNode } from 'react';

interface titleProps {
  className?: string;
  children: ReactNode;
}
export default function Title({ className, children }: titleProps) {
  return (
    <h2
      className={`${className} tracking-tighter leading-none font-bold text-5xl md:text-7xl max-w-[700px]`}
    >
      {children}
    </h2>
  );
}
