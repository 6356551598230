import Title from '../layouts/title';
import Description from '../layouts/description';
import SectionLayout from '../layouts/sectionLayout';

export default function Intro() {
  return (
    <SectionLayout id="intro">
      <Title>LONG STORY SHORT...</Title>
      <Description>
        xTRA BORED by BeePee is an artistic tribute to the BAYC community and my
        humble way, as an artist, to give something back to the club, even if
        it's just a little. <br />
        I've been part of this community for almost 2 years as a Mutant, but I
        still feel somewhat underexposed within the club. So, this is also an
        attempt to connect with as many of you as possible: Apes, mutants,
        penguins, frogs, cats, and any other living forms that are in this space
        for the fun ride.
        <br />
        <span className="text-xs text-gray-700 italic">
          *BAYC holders have full IP rights over their xTRA BORED Ape version.
          Enjoy it! 🦧
        </span>
      </Description>
    </SectionLayout>
  );
}
