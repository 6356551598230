import LogoWhiteTransparent from '../assets/Logo_White_Transparent.png';
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="bg-[#1F1F1F]">
      <div
        style={{
          backgroundImage: `url(${LogoWhiteTransparent})`,
          backgroundRepeat: 'no-repeat',
        }}
        className="py-6 px-2 bg-center bg-100 bg-left md:bg-50 lg:bg-50 text-neutral-400 flex flex-wrap justify-center gap-4 sm:bg-left sm:justify-end sm:items-center"
      >
        <div className="flex flex-col">
          <a href="#intro" className="hover:text-accent">
            Long story short...
          </a>
          <a href="#art" className="hover:text-accent">
            Same same, but different...
          </a>
          {/* <a href="#contribute" className="hover:text-accent">
            Dreams of an original ape...
          </a> */}
          <a href="#mutants" className="hover:text-accent">
            Mutants you say?
          </a>
        </div>
        <div className="sm:mr-10">
          <Link to="/gallery">
            <button
              type="button"
              className="rounded-md bg-white px-6 py-2 text-gray-500 text-sm font-semibold shadow-sm hover:bg-accent hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
            >
              xTRA BORED GALLERY
            </button>
          </Link>

          <div className="border rounded-md px-6 py-2 border-neutral-400 flex justify-center mt-2 hover:text-accent hover:border-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent">
            <a
              href="https://twitter.com/ZEP_BEEPEE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter className="block h-8 w-8" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
