import {
  FaArrowCircleRight,
  FaArrowCircleLeft,
  FaRandom,
} from 'react-icons/fa';

interface ShowcaseNavProps {
  inputValue: string;
  getPrevApe: () => void;
  getNextApe: () => void;
  getRandomApe: () => void;
}

const ShowcaseNavigator: React.FC<ShowcaseNavProps> = ({
  inputValue,
  getPrevApe,
  getNextApe,
  getRandomApe,
}) => {
  return (
    <div className="w-full flex justify-center gap-8 pb-10">
      <div className="w-1/2 flex justify-center gap-8 text-[#2b2b2b]">
        <button
          disabled={inputValue === '0'}
          onClick={getPrevApe}
          className={`${
            inputValue === '0'
              ? 'text-gray-400 cursor-not-allowed'
              : 'hover:text-accent '
          }`}
        >
          <FaArrowCircleLeft className="w-8 h-8" />
        </button>
        <button
          disabled={inputValue === '9999'}
          onClick={getNextApe}
          className={`${
            inputValue === '9999'
              ? 'text-gray-400 cursor-not-allowed'
              : 'hover:text-accent '
          }`}
        >
          <FaArrowCircleRight className="w-8 h-8" />
        </button>
      </div>
      <div className="w-1/2 flex justify-center gap-8">
        <button
          onClick={getRandomApe}
          className="h-full flex items-center px-4 text-white text-xs font-semibold shadow bg-[#2b2b2b] rounded hover:bg-accent focus:outline-none focus:ring-2 focus:ring-accent"
        >
          <FaRandom className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default ShowcaseNavigator;
