import { useState, useCallback } from 'react';

type UseModalStateReturn = {
  isXtraModalOpen: boolean;
  isOgModalOpen: boolean;
  isInfoModalOpen: boolean;
  openXtraModal: () => void;
  closeXtraModal: () => void;
  openOgModal: () => void;
  closeOgModal: () => void;
  openInfoModal: () => void;
  closeInfoModal: () => void;
};

const useModalState = (): UseModalStateReturn => {
  const [isXtraModalOpen, setIsXtraModalOpen] = useState<boolean>(false);
  const [isOgModalOpen, setIsOgModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);

  const openXtraModal = useCallback(() => setIsXtraModalOpen(true), []);
  const closeXtraModal = useCallback(() => setIsXtraModalOpen(false), []);

  const openOgModal = useCallback(() => setIsOgModalOpen(true), []);
  const closeOgModal = useCallback(() => setIsOgModalOpen(false), []);

  const openInfoModal = useCallback(() => setIsInfoModalOpen(true), []);
  const closeInfoModal = useCallback(() => setIsInfoModalOpen(false), []);

  return {
    isXtraModalOpen,
    isOgModalOpen,
    isInfoModalOpen,
    openXtraModal,
    closeXtraModal,
    openOgModal,
    closeOgModal,
    openInfoModal,
    closeInfoModal,
  };
};

export default useModalState;
