const ImageModal = ({ imageUrl, onClose }: any) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <button
        onClick={onClose}
        className="max-w-full max-h-screen overflow-auto"
      >
        <img
          src={imageUrl}
          alt="XTRA BORED"
          className="object-contain rounded-lg max-w-full max-h-screen md:max-w-[631px] md:max-h-[631px]"
        />
      </button>
    </div>
  );
};

export default ImageModal;
