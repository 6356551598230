import React from 'react';

interface ApeAttributeProps {
  icon: React.ReactNode;
  label: string;
  value?: string;
  isFlipped?: boolean;
}

const ApeAttribute: React.FC<ApeAttributeProps> = ({
  icon,
  label,
  value,
  isFlipped,
}) => {
  if (!value) {
    return null;
  }

  return (
    <span className="flex items-center my-4 mx-2">
      <span className="inline mr-2 text-accent-dark">{icon}</span>
      <span className="text-accent-dark mr-4">{label}: </span>
      {!isFlipped && <span className="mr-2">[xTRA]</span>}
      <span>{value}</span>
    </span>
  );
};
export default ApeAttribute;
