import { Disclosure } from '@headlessui/react';
import { FaXTwitter } from 'react-icons/fa6';
import LogoBanana from '../assets/Logo_Banana.png';
import { Link } from 'react-router-dom';

const NavBarGallery = () => {
  return (
    <header className="sticky top-0 z-50">
      <Disclosure as="nav" className="bg-white/90">
        {({ open }) => (
          <>
            <div className="px-6 py-4 md:px-10">
              <div className="flex h-auto justify-between">
                <div className="w-full flex justify-between items-center">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="h-14 md:h-20 w-auto"
                        src={LogoBanana}
                        alt="xTRA BORED"
                      />
                    </Link>
                  </div>
                  <div className="lg:ml-6 lg:flex lg:space-x-8">
                    <a
                      href="https://twitter.com/ZEP_BEEPEE"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="max-w-[115px] leading-none inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-bold text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      <FaXTwitter
                        className="block h-6 w-6 md:h-8 md:w-8"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default NavBarGallery;
