import SameSame from '../assets/SameSame.png';
import Title from '../layouts/title';
import Description from '../layouts/description';
import SectionLayout from '../layouts/sectionLayout';
import { Link } from 'react-router-dom';

export default function Traits() {
  return (
    <SectionLayout
      id="art"
      style={{
        backgroundImage: `url(${SameSame})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
      }}
      className="min-h-[900px] text-white bg-black sm:min-h-[500px] bg-100 sm:bg-50 xl:bg-fix"
    >
      <Title>SAME SAME, BUT DIFFERENT...</Title>
      <Description className="bg-black/50 sm:w-1/2 xl:w-2/3">
        I spent countless hours creating my own version of each trait and had to
        overcome some technical obstacles along the way (f*ck layering). This
        was done so that every BAYC owner and everyone else in the space would
        have the opportunity to see a different version of the Apes. <br />
        Some of the traits closely resemble the original, while others are quite
        distinct. Nonetheless, all of them have been freshly crafted with a
        great deal of love. <br />
        <b>Same same, but different, but still the same!</b>
      </Description>
      <div className="w-full pt-10">
        <Link to="/gallery">
          <button
            type="button"
            className="rounded-md bg-white px-6 py-2 text-gray-500 text-sm font-semibold shadow-sm hover:bg-accent hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
          >
            xTRA BORED GALLERY
          </button>
        </Link>
      </div>
    </SectionLayout>
  );
}
