import { ReactNode } from 'react';

interface sectionLayoutProps {
  className?: string;
  style?: any;
  id: string;
  children: ReactNode;
}
export default function SectionLayout({
  className,
  style,
  id,
  children,
}: sectionLayoutProps) {
  return (
    <section
      id={id}
      style={style}
      className={`${className} px-6 py-14 md:px-8 lg:px-20`}
    >
      {children}
    </section>
  );
}
