import MutantGreen from '../assets/MutantGreen.png';
import Title from '../layouts/title';
import Description from '../layouts/description';
import SectionLayout from '../layouts/sectionLayout';

export default function Traits() {
  return (
    <SectionLayout
      id="mutants"
      style={{
        backgroundImage: `url(${MutantGreen})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
      }}
      className="min-h-[400px] bg-[#D6FF00] bg-75"
    >
      <Title>MUTANTS YOU SAY?</Title>
      <Description className="md:w-2/3 font-semibold">
        I love Mutants, and that's something I'd love to pursue, but I know it
        will take me a significant amount of time. The future is uncertain, but
        let us have faith... 🧪👀
      </Description>
    </SectionLayout>
  );
}
