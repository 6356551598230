import { useState } from 'react';

const useImageLoader = (defaultImage: string) => {
  const [imageUrl, setImageUrl] = useState<string>(defaultImage);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadImage = (url: string) => {
    setIsLoading(true);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsLoading(false);
      setImageUrl(url);
    };
    img.onerror = () => {
      setIsLoading(false);
      setImageUrl(defaultImage);
    };
  };

  return { imageUrl, isLoading, loadImage };
};

export default useImageLoader;
