import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

interface SearchBarProps {
  inputValue: string;
  isInvalidValue: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
  onClearClick: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  inputValue,
  isInvalidValue,
  onInputChange,
  onKeyDown,
  onSearchClick,
  onClearClick,
}) => {
  return (
    <div className="flex flex-col text-base sticky top-20 z-50">
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        <div className="relative">
          <input
            className="w-full bg-gray-50 py-1 px-2 rounded text-gray-900 shadow focus:outline-none"
            placeholder="APE ID"
            type="text"
            value={inputValue}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
          <button
            onClick={onSearchClick}
            disabled={!inputValue || isInvalidValue}
            className={`absolute inset-y-0 right-0 flex items-center px-4 text-white shadow rounded-r focus:outline-none focus:ring-2 focus:ring-accent ${
              !inputValue || isInvalidValue
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#2b2b2b] hover:bg-accent'
            }`}
          >
            <FaMagnifyingGlass className="block h-4 w-4" aria-hidden="true" />
          </button>
        </div>
        <div>
          <button
            onClick={onClearClick}
            className="h-full flex items-center px-4 text-white shadow bg-red-500 rounded hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            CLEAR
          </button>
        </div>
      </div>
      {isInvalidValue && (
        <span className="text-center mt-2 text-red-400 font-semibold">
          Insert a valid ID (0-9999){' '}
        </span>
      )}
    </div>
  );
};

export default SearchBar;
